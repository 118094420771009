@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'ALS Hauss VF';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f8f9;
  color: #222222;
}

.container {
  max-width: 1020px;
  margin: 0 auto;
  padding: 20px;
}

@font-face {
  font-family: 'ALS Hauss VF';
  src:
    local('ALS Hauss VF'),
    url(./ALS_Hauss_VF.ttf) format('woff');
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
  }
}

.perfect-scroll::-webkit-scrollbar-track {
  background-color: rgba(100, 100, 100, 0.1);
}

/* width */
.perfect-scroll::-webkit-scrollbar {
  width: 7px;
  height: 4px;
}

/* Handle */
.perfect-scroll::-webkit-scrollbar-thumb {
  background: #8da399;
  border-radius: 10px;
}
